@import "variables";

@import "~bootstrap/scss/bootstrap.scss";

@font-face {
    font-family: Poppins;
    src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: PoppinsM;
    src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: PoppinsB;
    src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
    font-family: PoppinsL;
    src: url(../fonts/Poppins-Light.ttf);
}

a, svg, svg circle, img, span {
    transition: all 200ms ease-out;
}
::placeholder {
    font-family: Poppins;
    color: #c9c9c9;
}
::-ms-input-placeholder {
    font-family: Poppins;
    color: #c9c9c9;
}
.mt-120 {
    margin-top: 120px;
}

.mt-130 {
    margin-top: 130px;
}

.body-container {
    max-width: 900px;
    margin: 0 auto;
}

.only-mobile {
    display: none !important;
}

.only-desktop {
    display: block !important;
}

@media (max-width: 1200px) {
    .only-mobile {
        display: block !important;
    }
    
    .only-desktop {
        display: none !important;
    } 
}

/*-------------basic--------------------*/

a {
    text-decoration: none !important;
    transition: all 200ms ease-out;
}

.red-button {
    border-radius: 5px;
    background-color: #AF0D21;
    border: 1px solid #AF0D21;
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    font-family: PoppinsM;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    &:hover {
        background-color: #FFF;
        color: #AF0D21;
        border: 1px solid #AF0D21;
    }
}

.button-more-red {
    display: flex;
    padding: 4px 25px;
    margin-right: 20px;
    border-radius: 5px;
    background: #AF0D21;
    border: 1px solid #AF0D21;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: PoppinsM;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 218.182% */
    letter-spacing: 0.55px;
    text-transform: uppercase;
    &:hover {
        background-color: #FFF;
        color: #AF0D21;
        border: 1px solid #AF0D21;
    }
}
.button-more-blue {
    display: flex;
    padding: 4px 25px;
    margin-right: 20px;
    border-radius: 5px;
    background: #0033A1;
    border: 1px solid #0033A1;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: PoppinsM;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 218.182% */
    letter-spacing: 0.55px;
    text-transform: uppercase;
    &:hover {
        background-color: #FFF;
        color: #0033A1;
        border: 1px solid #0033A1;
    }
}
.button-more-white {
    display: flex;
    padding: 4px 25px;
    margin-right: 20px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #fff;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #0033A1;
    text-align: center;
    font-family: PoppinsM;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 218.182% */
    letter-spacing: 0.55px;
    text-transform: uppercase;
    &:hover {
        background-color: #0033A1;
        color: #fff;
        border: 1px solid #0033A1;
    }
}

/*-------------carousel-----------------*/

.carousel {
    height: 100vh;
    overflow: hidden;
}

.carousel-indicators {
    margin-bottom: 3rem;
    [data-bs-target] {
        width: 10px;
        height: 10px;
        border-radius: 40px;
        border: 0;
        border-top: 0;
        border-bottom: 0;
    }
}

.carousel-caption {
    position: absolute;
    max-width: 1320px;
    height: 100%;
    margin: 0 auto;
    display: flex !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    h2 {
        position: relative;
        width: fit-content;
        color: #FFF;
        font-family: Poppins;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 114.286% */
        letter-spacing: 1.75px;
        text-transform: uppercase;
        &::before {
            content: "";
            position: absolute;
            right: 4px;
            top: -12px;
            width: 20px;
            height: 4px;
            background: #AF0D21;
        }
    }
    p {
        color: #FFF;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 35px; /* 159.091% */
        letter-spacing: 1.1px;
        text-transform: uppercase;
    }
}
.carousel-caption-mobile {
    position: absolute;
    max-width: 1320px;
    height: 100%;
    margin: 50px auto;
    display: flex !important;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    .carousel-box {
        text-align: center;
    }
}
.carousel-item {
    display: flex !important;
    justify-content: center;
    width: 100%;
    position: relative;
    img.slideImg {
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        min-height: 100vh;
    }
}
.carousel-price-img {
    margin-bottom: 40px;
}
.carousel-box {
    .carousel-links {
        display: flex;
        flex-wrap: wrap;
        margin: 35px 0;
    }
    .carousel-links-bohem {
        width: 484px;
        justify-content: center;
        align-items: center;
    }
    .carousel-slidier-bohem {
        width: 450px;
        margin-top: 85px;
    }
}

.call-button-box {
    width: 378px;
    height: 70px;
    position: absolute;
    left: 40px;
    bottom: 150px;
    z-index: 999;
    a {
        display: block;
        height: 100%;
        width: 100%;
        transition: all 200ms ease-out;
        .call-icon-left, .call-icon-right {
            position: absolute;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fea741;
            border-radius: 35px;
            z-index: 10;
        }
        .call-icon-right {
            right: 0;
        }
        .call-icon-button {
            position: absolute;
            width: 308px;
            height: 70px;
            left: 0;
            font-family: PoppinsM;
            font-size: 22px;
            color: #000;
            text-transform: uppercase;
            background: #fea741;
            border-radius: 35px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            transition: all 200ms ease-out;
            z-index: 19;
        }
        &:hover .call-icon-button {
            left: 70px;
        }
    }
}


.carousel-linka {
    & > img {
        width: 825px !important;
        margin-left: -215px;
        margin-top: -55px !important;
    }
}

 @media (max-width: 767px) {
    .call-button-box {
        width: 378px;
        max-width: 100%;
        height: 70px;
        position: absolute;
        left: 0;
        bottom: -250px;
        z-index: 999;
        a {
            .call-icon-button {
                max-width: 100%;
            }
            &:hover .call-icon-button {
                left: 0px;
            }
        }
    }
}

@media (max-width: 776px) {
    .carousel-caption {
        h2 {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0.75px;
            width: max-content;
        }
        p {
            color: #FFF;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 35px;
            letter-spacing: 1.0px;
            text-transform: uppercase;
        }
    }

    .carousel-item:nth-of-type(2) {
        .carousel-caption {
            margin: 20px auto;
        }

    }
}


/*---------------carousel-product-------------*/

.carousel-products {
    .body-detail-carousel {
        position: relative;
        #customize-controls {
            position: absolute;
            top: 50%;
            width: 100%;
            z-index: 999;
            .prev {
                position: absolute;
                left: 25px;
                cursor: pointer;
                &:hover svg circle {
                    fill: #CC9E4F;
                }
            }
            .next {
                position: absolute;
                right: 25px;
                margin-right: 0px;
                cursor: pointer;
                &:hover svg circle {
                    fill: #CC9E4F;
                }
            }
        }
    }
    .black-pattern {
        img {
            width: 100px !important;
            margin-top: 44px;
        }
    }
    .white-pattern {
        img {
            margin-top: 48px !important;
            width: 185px !important;
        }
    }
}

/*-------------gallery slider------------*/

#lightbox {
    display: flex;
    flex-direction: column-reverse;
}

.lb-outerContainer {
    background-color: transparent !important;
    max-width: 100%;
}

.lb-details {
    display: none !important;
}

.lb-close {
    position: relative;
    width: 35px !important;
    height: 35px !important;
    margin: 15px 15px -55px 0;
    background: url(../images/btn_close_galerie.svg) center center no-repeat !important;
    z-index: 9999 !important;
}
.lb-nav a.lb-prev {
    opacity: 1 !important;
    background: url(../images/btn_prev_galerie.svg) 4% 48% no-repeat !important;
    z-index: 888 !important;
}
.lb-nav a.lb-next {
    background: url(../images/btn_next_galerie.svg) 98% 48% no-repeat !important;
    opacity: 1 !important;
    z-index: 888 !important;
}

/*-------------header-------------------*/

header {
    position: fixed;
    width: 100%;
    height: 70px;
    top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    & > div {
        position: fixed;
        z-index: 999;
    }
    .navigation-desktop {
        height: 70px;
        transition: all 200ms ease-out;
    }
    ul {
        li {
            padding: 0 18px !important;
            a {
                position: relative;
                padding: 0 6px !important;
                font-family: PoppinsM;
                font-size: 13px !important;
                font-style: normal;
                font-weight: 500 !important;
                line-height: 24px;
                letter-spacing: 0.6px;
                text-transform: uppercase;
                color: #fff;
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 2px;
                    bottom: 0;
                    left: 0%;
                    background-color: #AF0D21;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
                &.activeLink::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    transform: scaleX(1);
                    height: 2px;
                    bottom: 0;
                    left: 0%;
                    background-color: #AF0D21;
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
                &:hover::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                  }
            }
        }
    }
    .logo {
        margin-left: 50px;
    }
    .navbarmob {
        .logo {
            margin-left: 25px;
        }
    }
    .job-link {
        margin-right: 50px;
    }
    .red-button {
        display: inline-flex;
        width: 130px;
        height: 40px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
    }
}

header.header-other-pages {
    position: fixed;
    top: -1px; /*------fix iphone---------*/
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 999;
    ul {
        li {
            a {
                color: #000 !important;
            }
        }
    }
}

@media (max-width: 1500px) {
    header {
        ul {
            li {
                a {
                    padding: 0 0px !important;
                }
            }
        }
    }
}

.nav-container {
    position: fixed !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    .navbarmob {
        width: 100%;
    }
    .logo {
        position: absolute;
        left: 0;
        top: 15px;
    }
}
  
.navbarmob .menu-items {
    display: flex;
}
  
.navbarmob .nav-container li {
    list-style: none;
}
  
  .navbarmob .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-family: PoppinsL;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  }
  
  .navbarmob .nav-container a:hover{
      font-weight: bolder;
  }
  
  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 45px;
    top: 20px;
    right: 25px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  
  .nav-container .hamburger-lines {
    display: block;
    height: 19px;
    width: 45px;
    position: absolute;
    top: 28px;
    right: 25px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
  }
  
  .nav-container .hamburger-lines .line {
    display: block;
    height: 1px;
    width: 100%;
    border-radius: 10px;
    background: #fff;
  }

  .nav-container .black-lines .line {
    background: #000;
  }
  
  .nav-container .hamburger-lines .line1 {
    width: 63%;
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }
  
  .nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }
  
  .nav-container .hamburger-lines .line3 {
    width: 63%;
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }
  
  .navbarmob .menu-items {
    position: absolute;
    padding-top: 200px;
    background-color: #AF0D21;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    padding-left: 0px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
  }
  
.navbarmob .menu-items {
    ul {
        padding-left: 0;
        li {
            padding: 12px 0 !important;
            font-size: 1.5rem;
            font-weight: 500;
            list-style: none !important;
            a {
                display: flex;
                justify-content: center;
                font-size: 20px !important;
                color: rgba(0,0,0,0.30) !important;
                &.active {
                    width: max-content;
                    margin: 0 auto;
                    color: #fff !important;
                    font-family: PoppinsB;
                    font-size: 30px !important;
                    font-style: normal;
                    letter-spacing: 1.5px;
                    text-transform: uppercase;
                    &::before {
                        content: "";
                        position: absolute;
                        right: 2px;
                        top: -15px;
                        width: 20px;
                        height: 4px;
                        background: #CC9E4F;
                    }
                }
            }
        }
    }

    .mobileButton {
        .redMobileButton {
            display: inline-block;
            width: 280px;
            background: #fff;
            color: #AF0D21;
            border: 1px solid #AF0D21;
            border-radius: 5px;
            font-family: PoppinsM;
            font-size: 16px;
            line-height: 24px;
            margin: 50px 0 0 0;
            padding: 10px 0;
        }
    }
}
  
  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }
  
  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(40deg);
  }
  
  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }
  
  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-40deg);
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line {
    background: #fff;
  }
  
  .nav-container input[type="checkbox"]:checked ~ .logo{
    display: none;
  }

/*------------------footer--------------*/

footer {
    background: #FAFAFA;
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            a {
                color: #000;
                font-family: PoppinsM;
                font-size: 13px;
                font-style: normal;
                /* font-weight: 500; */
                line-height: 33px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                &:hover {
                    color: #AF0D21;
                }
            }
        }
    }
    .footer-first-row {
        padding: 100px 0;
    }
    .footer-second-row {
        border-top: 1px solid #EAEAEA;
        padding: 20px 0 24px;
        .footer-cookies, .footer-links {
            a {
                color: #000;
                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px; /* 366.667% */
                letter-spacing: 0.27px;
                &:hover {
                    color: #AF0D21;
                }
            }
        }
        .footer-cookies {
            a {
                margin-right: 20px;
            }
        }
        .footer-links {
            a {
                margin-left: 40px;
            }
        }
    }
    .footer-factory {
        a {
            display: flex;
            flex-direction: column;
            span:nth-of-type(1) {
                color: #000;
                font-family: PoppinsB;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 1.25px;
                text-transform: uppercase;
            }
            span:nth-of-type(2) {
                color: #000;
                font-family: PoppinsL;
                font-size: 17px;
                font-style: normal;
                font-weight: 275;
                line-height: 23px;
                letter-spacing: 0.85px;
                text-transform: uppercase;
                margin: 8px 0 16px;
            }
            &:hover {
                span:nth-of-type(1) {
                    color: #AF0D21;
                }
                svg circle {
                    fill: #CC9E4F;
                }
            }
        }
    }
}

@media (max-width: 990px) {
    footer {
        .footer-second-row {
            .footer-cookies {
                a {
                    margin: 0 20px;
                }
            }
            .footer-links {
                a {
                    margin: 0 20px;
                }
            }
        }
    }
}

/*-----------------contact--------------*/

.contact {
    margin-top: 180px;
    margin-bottom: 140px;
    .contact-box-1 {
        width: 445px;
        height: 425px;
        h2 {
            position: relative;
        }
        h2::before {
            content: "";
            position: absolute;
            right: 92px;
            top: -12px;
            width: 20px;
            height: 5px;
            background: #AF0D21;
        }
    }
    .contact-box-2 {
        width: 445px;
        height: 186px;
        h2 {
            font-size: 20px !important;
            margin-bottom: 15px !important;
        }
    }
    .contact-box-3 {
        width: 445px;
        height: 340px;
        h2 {
            position: relative;
        }
        h2::before {
            content: "";
            position: absolute;
            right: 46px;
            top: -12px;
            width: 20px;
            height: 5px;
            background: #AF0D21;
        }
    }
    .contact-box-4 {
        width: 445px;
        height: 306px;
    }
    .contact-box {
        display: flex;
        padding: 50px;
        margin-bottom: 15px;
        flex-shrink: 0;
        border-radius: 20px;
        background:#FAFAFA;
        flex-direction: column;
        justify-content: center;

        h2 {
            color: #000;
            font-family: PoppinsB;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            /* line-height: 35px; */
            text-transform: uppercase;
            margin-bottom: 0;
        }
        .contact-box-contact {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px;
            margin: 20px 0;
        }
        .contact-box-opentime {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 20px;
        }
        .contact-box-text {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 30px;
        }
        .contact-box-link {
            display: flex;
            a, button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                border-radius: 5px;
                background-color: #AF0D21;
                border: 1px solid #AF0D21;
                color: #FFF;
                text-align: center;
                text-transform: uppercase;
                font-family: PoppinsM;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                width: 140px;
                height: 40px;
                transition: all 200ms ease-out;
                &:hover {
                    background-color: #fff;
                    color: #AF0D21;
                }
            }
            a.greyLink {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                background: #F0F0F0;
                border: 1px solid #F0F0F0;
                &:hover {
                    background-color: #fff;
                    color: #AF0D21;
                    border: 1px solid #AF0D21;
                }
            }
        }
    }
}

@media (max-width: 893px) {
    .contact {
        .contact-box { 
            align-items: center;
            h2 {
                text-align: center;
                &::before {
                    content: none !important;
                }
            }
            .contact-box-text {
                text-align: center;
            }
        }
        .contact-box-1, .contact-box-2, .contact-box-3, .contact-box-4 {
            width: 100% !important;
        }
    }
}

/*------------------map api---------------*/

.wheretofind {
    margin-top: 70px;
    margin-bottom: 0px;
    height: 710px;
    width: 100%;
    #places {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 399;
        form {
            background: #fff;
            padding: 20px 30px;
            margin-top: 30px;
            border-radius: 40px;
            input {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                border-radius: 25px;
                background-color: transparent;
                border: 1px solid #AF0D21;
                color: #AF0D21;
                text-align: center;
                text-transform: uppercase;
                font-family: PoppinsM;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                width: 165px;
                height: 37px;
                transition: all 200ms ease-out;
                &.checkedButton {
                    background-color: #AF0D21;
                    color: #FFF;
                }
                &:hover {
                    background-color: #AF0D21;
                    color: #FFF;
                }
            }
        }
    }
    #map {
        position: relative;
        .legendBox {
            position: absolute;
            bottom: 15px;
            left: 15px;
            padding: 15px;
            background: #fff;
            border-radius: 10px;
            z-index: 999;
            .legendBox1, .legendBox2 {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
            }
            .legendBox3 {
                display: flex;
                align-items: center;
            }
            .legendBox1, .legendBox2, .legendBox3 {
                img {
                    width: 25px;
                }
                span {
                    line-height: normal;
                    padding-top: 2px;
                    padding-left: 5px;
                }
            }
        }
        .gMap {
            width: 100%;
            height: 710px;
            transition: none !important;
            .infowindow-office {
                padding: 15px;
                strong {
                    position: relative;
                    color: #000;
                    font-family: PoppinsB;
                    font-size: 18px;
                    font-style: normal;
                    line-height: 35px;
                    text-transform: uppercase;
                }
                div {
                    color: #000;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    line-height: 20px;
                }
                .navigation-button a{
                    display: flex;
                    padding: 4px 25px;
                    margin-right: 20px;
                    margin-top: 15px;
                    border-radius: 5px;
                    background: #AF0D21;
                    border: 1px solid #AF0D21;
                    flex-direction: column;
                    justify-content: center;
                    flex-shrink: 0;
                    color: #FFF;
                    text-align: center;
                    font-family: PoppinsM;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 218.182% */
                    letter-spacing: 0.55px;
                    text-transform: uppercase;
                    &:hover {
                        background-color: #FFF;
                        color: #AF0D21;
                        border: 1px solid #AF0D21;
                    }
                }
            }
        }
    }
}

@media (max-width: 990px) {
    .wheretofind {
        .inner {
            & > div {
                display: flex;
                flex-direction: column;
                label {
                    margin-bottom: 2px;
                }
            }
        }
    }
}

/*------------------products------------*/

.slideshow {
    margin: 0 0 0 0;
    z-index: 0;
}

.ourbeers {
    margin-top: 180px;
    margin-bottom: 140px;
    .priceMark {
        position: absolute;
        top: 25px;
        right: 22px;
        width: 70px;
        height: auto !important;
    }

    .beers-all {
        display: flex;
        justify-content: center;
        h2 {
            position: relative;
            display: flex;
            align-items: center;
            width: min-content;
            color: #000;
            font-family: PoppinsB;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 35px; /* 116.667% */
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin: 70px 0;
            &::before {
                content: "";
                position: absolute;
                right: 0px;
                top: -12px;
                width: 20px;
                height: 4px;
                background: #AF0D21;
            }
        }
    }
    a {
        .box-single-item {
            position: relative;
            height: 380px;
            width: 100%;
            margin-top: calc(var(--bs-gutter-x) * 0.5);
            margin-bottom: calc(var(--bs-gutter-x) * 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #FAFAFA;
            border-radius: 20px;
            transition: all 250ms ease-out;
            img {
                height: 270px;
                transition: all 250ms ease-out;
                z-index: 99;
            }
            span {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 33px; /* 275% */
                letter-spacing: 0.6px;
                text-transform: uppercase;
                transition: all 250ms ease-out;
                z-index: 99;
            }
        }
        .box-single-sp {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            img {
                margin-right: 35px;
                margin-left: 75px;
            }
            .box-single-item-special {
                text-align: left;
                z-index: 89;
                .box-single-item-special-new {
                    color: #17273c;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                }
                .box-single-item-special-text {
                    color: #17273C;
                    font-family: Poppins;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.75px;
                    text-transform: uppercase;
                }
                h2 {
                    position: relative;
                    color: #17273C;
                    font-family: PoppinsB;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 2.25px;
                    text-transform: uppercase;
                    margin: 43px 0 15px 0;
                    &::before {
                        content: "";
                        position: absolute;
                        right: 4px;
                        top: -25px;
                        width: 20px;
                        height: 4px;
                        background: #CC9E4F;
                    }
                }
            }
        }
        .red-pattern {
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/red-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #000;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
        }
        .blue-pattern{
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/blue-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #0033A1;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
        }
        .gold-pattern{
            &::before {
            content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/gold-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #CC9E4F;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
        }
        .silver-pattern{
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/silver-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #8E8E8E;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
        }
        .black-pattern {
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/black-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #AF0D21;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
            img {
                height: 220px;
                margin-top: 50px;
            }
        }
        .white-pattern{
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/white-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #AF0D21;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
            img {
                margin-top: 4px;
                height: 265px;
            }
        }
        .big-blue-pattern{
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: url(../images/big-blue-pattern.png);
                background-size: cover;
                border-radius: 20px;
                opacity: 0;
                transition: all 250ms ease-out;
            }
            span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 1px;
                    background: #CC9E4F;
                    /*box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);*/
                }
            }
        }
        &:hover {
            *::before {
                opacity: 1; 
            }
            img:not(.priceMark) {
                transform: rotate(34deg);
                scale: 1.25;
            }
            .white-pattern {
                img:not(.priceMark) {
                    transform: rotate(34deg);
                    scale: 1.1;
                }
            }
            span {
                color: #fff;
            }
            .white-pattern, .silver-pattern {
                span {
                    color: #000;
                }
            }
            .box-single-sp {
                .box-single-item-special {
                    .box-single-item-special-new {
                        color: #fff;
                    }
                    .box-single-item-special-text {
                        color: #fff;
                    }
                    h2 {
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media (max-width: 990px) {
    .ourbeers {
        margin-top: 90px;
        a {
            .box-single-sp {
                display: flex;
                justify-content: center;
                flex-direction: row;
                img {
                    margin-right: 10px;
                    margin-left: 0;
                }
            }

            &:hover {
                img:not(.priceMark) {
                    transform: rotate(0deg) !important;
                    scale: 1 !important;
                }
            }

        }

        .beers-all {
            h2 {
                text-align: center;
            }
        }
    }
}

.ourbeers-export {
    margin-top: -80px;
    .beers-all {
        h2 {
            width: initial;
        }
    }
}

@media (max-width: 990px) {
    .ourbeers-export {
        .beers-all {
            h2 {
                width: min-content;
            }
        }
    }
}


/*-------------modal product--------------*/

.modal-product {
    --bs-modal-width: 770px;
    .modal-content {
        width: 770px;
        height: 450px;
        display: flex;
        flex-direction: row;
        .modal-content-left {
            /*background: url(../images/silver-pattern.png) center center no-repeat;*/
            background-size: cover;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            .modal-content-beer-box {
                img {
                    width: 170px;
                }
            }
        }
        .modal-content-right {
            width: 470px;
            padding: 20px 40px;
            overflow: scroll;
            overflow-x: hidden;
            .modal-body {
                .btn-close {
                    position: absolute;
                    right: -25px;
                    top: -5px;
                }
                .modal-body-header {
                    h2 {
                        color: #AF0D21;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                    }
                    h3 {
                        color: #000;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
                .modal-body-table {
                    table {
                        width: 100%;
                        tr {
                            td {
                                color: #000;
                                font-family: Poppins;
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 22px;
                                padding: 12px 20px;
                                &:nth-of-type(2) {
                                    text-align: right;
                                }
                            }
                            &:nth-child(even) {
                                background:#FAFAFA;
                            }
                        }
                    }
                }
                .modal-body-body {
                    h2 {
                        color: #AF0D21;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                        margin-top: 50px;
                    }
                    .modal-body-body-text {
                        color: #000;
                        text-align: justify;
                        font-family: Poppins;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                .modal-body-footer {
                    h2 {
                        color: #AF0D21;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                        margin-top: 50px;
                    }
                    .modal-body-footer-profil {
                        display: inline-flex;
                        align-items: center;
                        flex-shrink: 0;
                        img {
                            max-width: 100%;
                            height: 310px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 990px) {
    .modal-product {
        .modal-content {
            max-width: 97%;
        }
    }
}

/*--------------carousel products---------*/

.carousel-products {
    margin-bottom: 160px;
    .item {
        a {
            .box-single-item {
                width: 260px;
                height: 360px;
                flex-shrink: 0;
                border-radius: 20px;
                background:#FAFAFA;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 130px;
                    &.smaller-design {
                        width: 190px;
                    }
                }
                span {
                    color: #000;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 33px; /* 330% */
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }
            .box-single-sp {
                width: 425px;
                flex-direction: row;
                background: url(../images/big-blue-pattern.png) center center no-repeat;
                background-size: cover;
                .box-single-item-special {
                    text-align: left;
                    h2 {
                        color: #fff;
                        font-family: Poppins;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 24px;
                        letter-spacing: 1.5px;
                        text-transform: uppercase;
                    }
                    .box-single-item-special-text {
                        color: #fff;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                        margin: 10px 0 0;
                    }
                }
            }
            &:hover {
                .box-single-item {
                    img {
                        transform: rotate(34deg);
                        scale: 1.15;
                    }
                }
                .box-single-item.white-pattern {
                    img {
                        transform: rotate(34deg);
                        scale: 1.05;
                    }
                }
            }
        }
    }
}


/*--------------factory visit-------------*/

.visit-factory {
    margin: 0 0 150px 0;
    .factory-button {
        display: flex;
        justify-content: center;
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            span:nth-of-type(1) {
                color: #000;
                text-align: center;
                font-family: PoppinsB;
                font-size: 30px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 1.5px;
                text-transform: uppercase;
            }
            span:nth-of-type(2) {
                color: #000;
                font-family: PoppinsL;
                font-size: 24px;
                font-style: normal;
                line-height: 35px;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin: 8px 0 24px;
            }
            &:hover {
                span:nth-of-type(1) {
                    color: #AF0D21;
                }
                svg circle {
                    fill: #CC9E4F;
                }
            }
        }
    }
}

/*-----------------history----------*/

.history {
    margin-top: 170px;
    margin-bottom: 150px;
    .creation {
        margin-bottom: 80px;
        h2, .history-text {
            padding-right: 0px !important;
        }

        h2::before {
            right: 42% !important;
        }
    }
    .history-single-box {
        margin-top: -20px;
        img {
            width: 420px;
            max-width: 100%;
            border-radius: 20px
        }
        h2 {
            position: relative;
            color: #000;
            font-family: PoppinsB;
            font-size: 35px;
            font-style: normal;
            line-height: 35px; /* 100% */
            text-transform: uppercase;
        }
        .history-text {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            line-height: 20px; 
            strong {
                font-family: PoppinsB;
            }
        }
        .history-single-box-left {
            text-align: right;
            h2, .history-text {
                padding-right: 30px;
            }
            h2 {
                &::before {
                    content: "";
                    position: absolute;
                    right: 30px;
                    top: -12px;
                    width: 20px;
                    height: 5px;
                    background: #AF0D21;
                }
            }
        }
        .history-single-box-right {
            text-align: left;
            h2, .history-text {
                padding-left: 30px;
            }
            h2 {
                &::before {
                    content: "";
                    position: absolute;
                    left: 30px;
                    top: -12px;
                    width: 20px;
                    height: 5px;
                    background: #AF0D21;
                }
            }
        }
    }
}

@media (max-width: 692px) {
    .history {
        margin-bottom: 40px;
        .history-single-box {
            img {
                margin: 15px 0 85px;
            }
        }
        .history-single-box-left {
            text-align: center !important;
            h2 {
                padding-right: 0 !important;
                &::before {
                    content: "";
                    position: absolute;
                    right: auto;
                    left: 53.5%;
                    top: -12px;
                    width: 20px;
                    height: 5px;
                    background: #AF0D21;
                }
            }
            .history-text {
                padding-right: 0 !important;
            }
        }
    }
}

/*---------------beer detail--------------*/

.beer-detail {
    .header-detail {
        .container-price {
            position: relative;
            img.priceImg {
                position: absolute;
                right: 50px;
                top: -70px;
            }
            img.priceImgBlack {
                position: absolute;
                right: 61px;
                top: -70px;
            }
        }
        height: 999px;
        width: 100%;
        padding-top: 70px;
        margin-top: 70px;
        h1 {
            position: relative;
            width: fit-content;
            margin: 45px auto 10px;
            color: #FFF;
            text-align: center;
            font-family: PoppinsB;
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px; /* 60% */
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        .detail-text-short {
            color: #FFF;
            text-align: center;
            font-family: PoppinsL;
            font-size: 30px;
            font-style: normal;
            line-height: 40px; /* 133.333% */
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin-bottom: 60px;
        }
        img.floating-img {
            width: 420px;
            height: auto;
        }
        img.floating-img-small {
            width: 420px;
            height: auto;
        }
        img.floating-img-musketyr {
            width: 500px;
        }
    }

    .body-detail {
        h2 {
            color: #000;
            font-family: PoppinsB;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 80% */
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
        h3 {
            color: #000;
            font-family: PoppinsL;
            font-size: 22px;
            font-style: normal;
            line-height: 28px;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            margin-bottom: 60px;
        }
        .type-box {
            width: 290px;
            height: 360px;
            border-radius: 20px;
            background: #FAFAFA;
            .type-box-img {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                width: 100%;
                height: 300px; 
                padding-bottom: 10px;
                .detail-plech {
                    height: auto;
                    width: 74px;
                }
                .detail-sud {
                    height: auto;
                    width: 210px;
                }
            }
            .type-box-text {
                width: 100%;
                height: 60px; 
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px; /* 330% */
                letter-spacing: 0.5px;
                /*text-transform: uppercase;*/
            }
        }
    }

    .body-detail-other {
        h2 {
            color: #000;
            text-align: center;
            font-family: PoppinsB;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 33px; /* 110% */
            letter-spacing: 1.5px;
            text-transform: uppercase; 
            margin: 0;
        }
        h3 {
            color: #000;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 275;
            line-height: 33px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            margin: 0;
        }
        .type-other-box-1 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 290px;
            height: 290px;
            flex-shrink: 0;
            background: url(../images/pivo/img_zatecky_chmel.jpg) center center no-repeat;
            border-radius: 20px;
            .type-other-box-img {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 27px;
            }
        }
        .type-other-box-2 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 290px;
            height: 290px;
            flex-shrink: 0;
            background: url(../images/pivo/img_krusovicka_voda.jpg) center center no-repeat;
            border-radius: 20px;
            .type-other-box-img {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 27px; 
            }
        }
        .type-other-box-3 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 290px;
            height: 290px;
            flex-shrink: 0;
            background: url(../images/pivo/img_moravsky_jecmen.jpg) center center no-repeat;
            border-radius: 20px;
            .type-other-box-img {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 25px;
                font-style: normal;
                font-weight: 700;
                line-height: 27px; 
            }
        }

        .info-box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 150px;
            table {
                tr {
                    display: flex;
                    td {
                        width: 345px;
                        height: 100px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .info-text-1 {
                            color: #000;
                            text-align: center;
                            font-family: PoppinsB;
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 700;
                        }
                        .info-text-2 {
                            color: #000;
                            text-align: center;
                            font-family: Poppins;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                        }
                        &:nth-of-type(1) {
                            border-right: 1px solid #E6E6E6;
                        }
                        &:nth-of-type(2) {
                            .info-text-1, .info-text-2 {
                                color: #AF0D21;
                            }
                        }
                    }
                    &:nth-of-type(1) {
                        border-bottom: 1px solid #E6E6E6;
                        .info-text-1 {
                            margin-top: -18px;
                        }
                    }
                    &:nth-of-type(2) {
                        td {
                        }
                        .info-text-2 {
                            margin-bottom: -18px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 893px) {
    .beer-detail {
        .header-detail {
            .img-floating-fix {
                width: 370px;
            }
            .container-price {
                img {
                    &.priceImg {
                        right: 10px;
                    }
                }
            }
        }

        .body-detail {
            .type-box {
                width: 100%;
                margin-bottom: 10px;
                .type-box-img {
                    .detail-sklo-sm {
                        width: 150px;
                        margin-bottom: -50px;
                    }
                    .detail-sklo-musketyr {
                        width: 235px;
                    }
                }
            }
        }

        .body-detail-other {
            .type-other-box-1, .type-other-box-2, .type-other-box-3 {
                width: 600px;
                max-width: 100%;
                margin: 0 auto 10px;
                /* margin-bottom: 10px; */
                border-radius: 20px;
            }
            .info-box {
                table {
                    tr {
                        flex-direction: column;
                        border: none !important;
                        td:nth-of-type(n) {
                            border: none !important;
                        }
                    }
                }
            }
        }
        &.musketyr {
            .header-detail {
                height: 860px;
            }
        }
    }
}

.desitka {
    .header-detail {
        background: url(../images/pivo/desitka/desitka_B.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #fff;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #CC9E4F;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.imperial {
    .header-detail {
        background: url(../images/pivo/imperial/header_detail_piva_imperial.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #fff;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #CC9E4F;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.bohem {
    .header-detail {
        background: url(../images/pivo/bohem/bohem.jpg) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #fff;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #CC9E4F;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.cerne {
    .header-detail {
        background: url(../images/pivo/cerne/cerne_B.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #fff;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 0px;
                background: #AF0D21;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
        .detail-text-short {
            color: #fff;
        }
    }
}

.musketyr {
    .header-detail {
        background: url(../images/pivo/musketyr/musketyr_B.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #000;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #AF0D21;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
        .detail-text-short {
            color: #000;
        }
    }
}

.nealko {
    .header-detail {
        background: url(../images/pivo/nealko/nealko_B.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #fff;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #CC9E4F;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.dvanactka {
    .header-detail {
        background: url(../images/pivo/dvanactka/dvanactka_B.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #000;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #AF0D21;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
        .detail-text-short {
            color: #000;
        }
        .detail-text-short {
            color: #000;
        }
    }
}

.lezak {
    .header-detail {
        background: url(../images/pivo/lezak/lezak_B.png) top center no-repeat;
        background-size: auto 780px;
        h1 {
            color: #000;
            &::before {
                content: "";
                position: absolute;
                width: 30px;
                height: 6px;
                top: -25px;
                right: 3px;
                background: #8E8E8E;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
        .detail-text-short {
            color: #000;
        }
        .detail-text-short {
            color: #000;
        }
    }
}

button.specialButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    span:nth-of-type(1) {
        color: #000;
        font-family: PoppinsB;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
    }
    span:nth-of-type(2) {
        color: #000;
        font-family: PoppinsL;
        font-size: 17px;
        font-style: normal;
        font-weight: 275;
        line-height: 23px;
        letter-spacing: 0.85px;
        text-transform: uppercase;
        margin: 8px 0 16px;
    }
    &:hover {
        background: none;
        border: none;
        span:nth-of-type(1) {
            color: #AF0D21;
        }
        svg circle {
            fill: #CC9E4F;
        }
    }
    &:active {
        background: none !important;
        border: none;
    }
}

/*------------------aboutus---------------*/

.aboutus {
    margin: 200px 0 140px 0;
    .aboutus-box-left {
        padding-right: 15px;
    }
    .aboutus-box-right {
        padding-left: 15px;
        p:nth-of-type(1) {
            margin-top: 100px !important;
        }
        p {
            padding: 0 30px;
        }
        img {
            margin-top: 130px;
        }

    }
    .aboutus-box {
        h1 {
            color: #000;
            font-family: PoppinsB;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            text-transform: uppercase;
        }
        p {
            b {
                color: #AF0D21;
            }
        }
        p:nth-of-type(1) {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: 30px;
        }

        p:nth-of-type(2) {
            color: #000;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        button {
            margin: 45px 0 110px;
            color: #FFF;
            text-align: center;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            width: 130px;
            height: 40px;
            border-radius: 5px;
            background: #AF0D21;
            border: 1px solid #AF0D21;
            transition: all 200ms ease-out;
            &:hover {
                background: #fff;
                color: #AF0D21;
            }
        }
        img {
            border-radius: 20px;
        }
    }
    .aboutus-result-section {
        margin-top: 150px;
        .aboutus-result {
            .aboutus-result-h2 {
                color:  #AF0D21;
                text-align: center;
                font-family: PoppinsB;
                font-size: 35px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px; /* 114.286% */
                text-transform: uppercase;
                span {
                    font-size: 20px;
                }
            }
            .aboutus-result-black {
                color: #000 !important;
            }
            .aboutus-result-text {
                color: #000;
                text-align: center;
                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
    .aboutus-galery-section {
        margin-top: 150px;
        .galery-box {
            position: relative;
            width: 100%;
            height: 255px;
            overflow: hidden;
            border-radius: 20px;
            margin-bottom: 24px;
            img {
                position: absolute;
                height: 100%;
                left: 50%;
                margin-left: -200px;
                border-radius: 20px;
            }
        }
    }
}

@media (max-width: 692px) {
    .aboutus {
        margin: 200px 0 100px 0;
        .aboutus-box-left {
            padding: 0;
        }
        .aboutus-box-right {
            padding: 0;
            p {
                padding: 0 0 25px;
            }
            img {
                margin-top: 60px;
            }
        }
        .aboutus-box {
            button {
                margin: 45px 0 75px;
            }
        }
        .aboutus-result-section {
            &>div:nth-of-type(1), div:nth-of-type(2) {
                margin-bottom: 45px;
            }
        }
    } 
}

/*-----------------modal--------------*/

#ageCheck, #ageCheckFalse {
    .modal-content {
        display: flex;
        justify-content: center;
        background: #fff url(../images/symbol_krusovice.svg) center center no-repeat;
        img {
            width: 245px;
        }
        .ageText {
            position: relative;
            display: flex;
            align-items: center;
            /* width: min-content; */
            color: #000;
            font-family: PoppinsB;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 35px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin: 70px 0;
            justify-content: center;
        }
        .footerAgeCheck {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        button {
            border-radius: 5px;
            background-color: #AF0D21;
            border: 1px solid #AF0D21;
            color: #FFF;
            text-align: center;
            text-transform: uppercase;
            font-family: PoppinsM;
            font-size: 16px;
            font-style: normal;
            /* font-weight: 500; */
            /* line-height: 24px; */
            width: 125px;
            height: 40px;
            transition:  all 200ms ease-out;
            &:hover {
                color: #AF0D21;
                background-color: #fff;
            }
        }
        .docLinks {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            a {
                color: #000;
                font-family: PoppinsM;
                font-size: 13px;
                font-style: normal;
                /* font-weight: 500; */
                line-height: 33px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                margin: 0 15px;
            }
        }
    }
}

.modal-dialog-form {
    width: 480px;
    max-width: 100%;
    .btn-close {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .modal-content {
        width: 96%;
    }
    .modal-header {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: none;
        max-width: 100%;
        margin-top: 25px;
        h1 {
            font-family: PoppinsB;
            font-size: 16px !important;
            color: #AF0D21;
            max-width: 100%;
        }
        h2 {
            font-family: Poppins;
            font-size: 14px !important;
            color: #000;
            max-width: 100%;
        }
    }
    .modal-body {
        .form-block {
            text-align: center;
            max-width: 100%;
            input:not([type='checkbox']), select {
                width: 370px;
                max-width: 100%;
                margin: 0px auto 5px;
                padding: 14px 20px;
                background: #fafafa;
                border: 1px solid #f2f2f2;
                border-radius: 5px;
                font-family: Poppins;
                font-size: 14px;
                color: #000;
                &::placeholder {
                    font-family: Poppins;
                    color: #c9c9c9;
                    font-size: 14px;
                }
            }
            input#form_time {
                /* Arrow */
                appearance: none;
                background-image: url("../images/sipka_vravo.svg");
                background-repeat: no-repeat;
                background-position: right 0.7rem top 55%;
                background-size: 0.45rem auto;
                &::placeholder {
                    color: #000 !important;
                }
            }
            select {
                option:first-child {
                    color:#000;
                }
                /* Arrow */
                appearance: none;
                background-image: url("../images/sipka_vravo.svg");
                background-repeat: no-repeat;
                background-position: right 0.7rem top 55%;
                background-size: 0.45rem auto;
            }
            textarea {
                width: 370px;
                height: 225px;
                max-width: 100%;
                margin: 0px auto 5px;
                padding: 14px 20px;
                background: #fafafa;
                border: 1px solid #f2f2f2;
                border-radius: 5px;
                font-family: Poppins;
                color: #000;
                margin-top: 25px;
            }
        }
        .form-block-special {
            display: flex;
            align-items: center;
            width: 370px;
            max-width: 100%;
            height: 75px;
            margin: 0 auto 5px;
            background: #fafafa;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            .form-block-special-text {
                width: 65%;
                text-align: left;
                padding-left: 25px;
            }
            .form-block-special-buttons {
                width: 35%;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                    cursor: pointer;
                }
                input {
                    max-width: 100%;
                    width: 45px;
                    height: 45px;
                    padding: 0;
                    margin: 0;
                    border-radius: 25px;
                    text-align: center;
                    color: #AF0D21;
                    background-color: #fff;
                    font-weight: 900;
                }
            }
        }
        .form-block-agree {
            width: 370px;
            height: 45px;
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fafafa;
            border: 1px solid #f2f2f2;
            border-radius: 5px;
            margin-top: 25px;
            margin-bottom: 25px;
            label {
                margin-top: 2px;
            }
        }

        .form-block-header {
            text-align: center;
            width: 370px;
            margin: 50px auto 10px;
            max-width: 100%;
            h2 {
                font-family: PoppinsB;
                font-size: 16px !important;
                color: #AF0D21;
            }
            h3 {
                font-family: Poppins;
                font-size: 14px !important;
                color: #000;
            }
        }
    }

    .modal-footer {
        width: 370px;
        margin: 0 auto 50px;
        padding: 0;
        border: none;
        justify-content: center;
        .finalprice {
            font-family: PoppinsB;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            color: #000;
            line-height: 40px;
        }
        button {
            border-radius: 5px;
            background-color: #AF0D21;
            border: 1px solid #AF0D21;
            color: #FFF;
            text-align: center;
            text-transform: uppercase;
            font-family: PoppinsM;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            width: 100%;
            max-width: 100%;
            transition: all 300ms ease-out;
            &:hover {
                background-color: #fff;
                color: #AF0D21; 
            }
        }
    }
}
